<template>
  <v-container fluid class="pa-0">
    <side-menu :main-page="$route.name" />

    <div class="ma-0">
      <div style="padding: 0 139px 150px 395px">
        <v-form ref="form">
          <v-col cols="4" class="pa-0">
            <v-select
              class="mt-7 mb-10"
              label="STATUS"
              :items="app_status.items"
              mandatory
              v-model="app_status.selected"
            />
          </v-col>

          <div class="mb-10">
            <v-text-field
              label="PATH"
              class="mr-4"
              type="text"
              v-model="namespace.path"
              :rules="[rules.required]"
              :messages="pathMsg"
              @input="pathChanged"
              :disabled="$route.name === 'NameSpacesEdit'"
            >
              <template v-slot:append-outer>
                <v-icon color="#3ea55e" v-if="pathChecked">
                  mdi-check-circle
                </v-icon>
                <v-icon v-else @click="doPathCheck">
                  mdi-check-circle-outline
                </v-icon>
              </template>
            </v-text-field>
            <p style="color: #3ea55e">Ex, ‘skos/hoge/fuga’</p>
            <p class="ma-0 grey--text text-darken-4">
              {{ apiHost }}/ns/
              <span style="color: #3ea55e">{{ namespace.path }}</span>
            </p>
          </div>

          <v-textarea label="NOTE" />

          <v-text-field
            label="FORWARD to"
            class="mb-10"
            v-model="redirect"
            placeholder="https://"
            :rules="
              app_status.selected === 'redirect'
                ? [rules.required, rules.isURL]
                : undefined
            "
            v-if="app_status.selected === 'redirect'"
          />
        </v-form>

        <div
          class="d-flex justify-center mt-9 pt-8"
          style="border-top: 6px solid #c6c6c6"
        >
          <v-btn outlined>
            {{ getWords("common_button_cancel") }}
          </v-btn>
          <v-btn
            class="ml-4"
            outlined
            color="#8F5F98"
            :loading="saving"
            :disabled="saving"
            @click="saveBtnPressed"
          >
            {{ getWords("common_button_save") }}
          </v-btn>
        </div>

        <v-snackbar v-model="snackbar">
          {{ getWords("create_snack_message") }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              {{ getWords("common_button_close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>

<script>
import SideMenu from "../../components/SideMenu";
import {
  db,
  fb,
  getUserID,
  getUserNamespacesList,
  getUserNamespaceWithPath,
  getNamespaceWithId,
  FB_API_HOST,
} from "../../plugins/firebase";
import words from "../../assets/localewords.json";

export default {
  name: "create",
  data: () => ({
    saving: false,
    snackbar: false,
    pathChecked: false,
    app_status: {
      items: ["draft", "reserved", "redirect"],
      selected: "draft",
    },
    rules: {
      required: (v) => (v && !!v) || "Required.",
      isURL: (v) => v.includes("http") || "Must be URL",
    },
    pathMsg: ["You need double check."],
    namespace: {
      route: "",
      path: "",
      status: "draft",
      created: new Date(),
      updated: new Date(),
      creator: "",
      users: [],
    },
    redirect: "",
    users: [],
    apiHost: FB_API_HOST,
  }),
  components: { SideMenu },
  created: function () {
    this.initialize();
  },
  watch: {
    $route() {
      this.initialize();
    },
    getEditParams: function (to, from) {
      if (to.id !== from.id) {
        this.initialize();
      }
    },
  },
  computed: {
    getEditParams() {
      return this.$store.state.editParams;
    },
    getUserList() {
      if (this.users.length === 0) {
        this.callGetUserList();
      }
      return this.users;
    },
    getEditableUsers() {
      const usrs = this.users.filter((user) => user.member || user.creator);
      return usrs.length;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  methods: {
    initialize: async function () {
      this.redirect = "";
      this.users = [];
      this.namespace = this.initNameSpace();
      this.saving = false;
      this.snackbar = false;
      this.app_status.selected = "draft";
      this.pathChecked = false;
      if (this.$route.name === "NameSpacesEdit") {
        const params = this.getEditParams;
        if (params.id === "") {
          this.$router.push({ name: "Type" }).catch((err) => console.log(err));
          return;
        }
        this.namespace = await getNamespaceWithId(params.id);
        this.app_status.selected = this.namespace.status;
        if (this.namespace.forwardTo) {
          this.redirect = this.namespace.forwardTo;
        }
        this.pathChecked = true;
      }
    },
    initNameSpace: function () {
      return {
        route: "",
        path: "",
        status: "draft",
        created: new Date(),
        updated: new Date(),
        creator: "",
        users: [],
      };
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    doPathCheck: async function () {
      if (this.namespace.path === "") {
        this.pathChecked = false;
        this.pathMsg = [this.getWords("ns_message1")];
        return;
      }
      const resultPath = await this.pathCheck();
      this.pathChecked = resultPath === "";
      if (this.pathChecked) {
        this.pathMsg = [this.getWords("ns_message2")];
      } else {
        this.pathMsg = [this.getWords("ns_message3-1") + resultPath + this.getWords("ns_message3-2")];
      }
    },
    pathChanged: function () {
      this.pathChecked = false;
      this.pathMsg = [this.getWords("ns_message1")];
    },
    saveBtnPressed: async function () {
      if (this.saving) {
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.app_status.selected === "redirect") {
          if (this.redirect.includes("http") === false) {
            return;
          }
        }
      } else {
        return;
      }
      if (this.pathChecked === false) {
        await this.doPathCheck();
        if (this.pathChecked === false) {
          return;
        }
      }
      this.saving = true;
      if (this.$route.name === "NameSpacesCreate") {
        const identifier = await this.saveNewOnDB();
        console.log(identifier);
        this.snackbar = true;
        this.saving = false;
        const data = await getUserNamespacesList();
        this.$store.dispatch("commitNamespaces", data);
        this.$store.dispatch("commitEditParams", {
          mode: "namespaces",
          id: identifier,
        });
        this.$router
          .push({ name: "NameSpacesEdit" })
          .catch((err) => console.log(err));
      } else if (this.$route.name === "NameSpacesEdit") {
        await this.saveOverOnDB();
        this.saving = false;
        this.snackbar = true;
      } else {
        this.saving = false;
      }
    },
    createSaveDict: function (namespaceObj, isNew) {
      const time = fb.firestore.FieldValue.serverTimestamp();
      const obj = {};
      obj.creator = namespaceObj.creator;
      obj.users = namespaceObj.users;
      obj.path = namespaceObj.path;
      obj.status = namespaceObj.status;
      if (obj.status === "redirect") {
        obj.forwardTo = namespaceObj.forwardTo;
      }
      obj.created = isNew ? time : namespaceObj.created;
      obj.updated = time;
      return obj;
    },
    saveNewOnDB: async function () {
      const newDoc = db.collection("namespaces").doc();
      console.log(newDoc.id);
      const uid = getUserID();
      if (this.users.filter((id) => id === uid).length === 0) {
        this.users.push(uid);
      }
      this.namespace.creator = uid;
      this.namespace.users = this.users;
      this.namespace.status = this.app_status.selected;
      if (this.app_status.selected === "redirect") {
        this.namespace["forwardTo"] = this.redirect;
      }
      const dict = this.createSaveDict(this.namespace, true);
      const batch = db.batch();
      batch.set(newDoc, dict);
      await batch.commit();
      return newDoc.id;
    },
    saveOverOnDB: async function () {
      const params = this.getEditParams;
      const upDoc = db.collection("namespaces").doc(params.id);
      const uid = getUserID();
      if (this.users.filter((id) => id === uid).length === 0) {
        this.users.push(uid);
      }
      this.namespace.users = this.users;
      this.namespace.status = this.app_status.selected;
      if (this.app_status.selected === "redirect") {
        this.namespace["forwardTo"] = this.redirect;
      }
      const dict = this.createSaveDict(this.namespace, true);
      const batch = db.batch();
      batch.set(upDoc, dict);
      await batch.commit();
    },
    pathCheck: async function () {
      const resultPath = await getUserNamespaceWithPath(this.namespace.path);
      return resultPath;
    },
  },
};
</script>
<style scoped lang="scss"></style>
